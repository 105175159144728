import React from "react"
import "../styles/mindbody.scss"
import accountStyles from "../components/account.module.scss"
import Layout from '../components/layout-default'
import Breadcrumbs from "../components/breadcrumbs";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Account",
  },
];

const AccountPage = () => (
  <Layout
    title="Create Your Account to Join RF Dance"
    description="Want to become a member of RF Dance studio or book your dance classes in Orange County? Sing up to create your account and unlock your dance potential!" 
    pathname="account/"
    noHero="no-hero"
    className="pages">
    <div className="container">
      <Breadcrumbs crumbs={crumbs} />
      <iframe 
        className={accountStyles.iframe}
        title="Account login"
        id="mindbody_branded_web_cart_modal" 
        // onLoad="window.send_information_to_mindbody_for_great_justice('mindbody_branded_web_cart_modal')" 
        frameBorder="0" 
        marginWidth="0" 
        marginHeight="0" 
        scrolling="yes"
        src="https://widgets.healcode.com/sites/62245/client/schedules" />
    </div>
  </Layout>
)

export default AccountPage